
<template>
  <div class="Manage_4">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect_top">
        <el-menu-item index="1">销售线索</el-menu-item>
        <el-menu-item index="2">客户管理</el-menu-item>
        <el-menu-item index="3">订单管理</el-menu-item>
        <el-menu-item index="4">合同管理</el-menu-item>
        <el-menu-item index="5">数据报表</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：这里可以管理线上平台销售的订单与线下个人创建的全部订单
      </div> 
    </div>
    <div class="tab_Bor"> 
      <div class="T1 bord">
            <el-tooltip class="item" effect="dark" content="订单的来源" placement="top">
              <span style="float:left;margin-right:10px">合同项目<i class="el-icon-question"></i>:</span>
            </el-tooltip>
        <div class="T2" style="margin-left:24px">
          <div class="Tuyiiu" @click="Activ(1, 0)" :class="0 == leads_source_value ? 'Actits' : ''">全部</div>
          
          <div @click="Activ(1 , item.goods_id)" :class="item.goods_id == leads_source_value ? 'Actits' : ''"
                       v-for="item in leads_source" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id">{{item.goods_name}}</div> 
        </div>
      </div>
      <div class="T1 bord">
        <el-tooltip class="item" effect="dark" content="销售的项目" placement="top">
          <span style="float:left;margin-right:10px">付款形式<i class="el-icon-question"></i>:</span>
        </el-tooltip>
        <div class="T2" style="margin-left:24px">
          <div class="Tuyiiu" @click="Activ(2, 0)" :class="0 == leads_demand_value ? 'Actits' : ''">全部</div>
          <div @click="Activ(2 , item.id)" :class="item.id == leads_demand_value ? 'Actits' : ''" 
                        v-for="item in pay_form"   :key="item.id" :label="item.name" :value="item.id">{{item.name}}</div>
          <div @click="AddXs(2)">管理销售项目</div>
        </div>
      </div>
      
      <div class="T1">
        <div style="flex:1.8">
          <span style="float:left;margin-right:10px">创建时间：</span>
          <div style="float:left;" class="Topo">
            <el-date-picker v-model="Months1" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            <span style="margin: 0 15px 0 15px;display: inline;">至</span>
            <el-date-picker v-model="Months2" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
          </div>
        </div>

        <div style="flex:1">
          <span style="float:left;margin-right:10px">合同金额：</span>
          <div style="float:left;" class="Topo ">
            <el-input v-model="Months3" placeholder="输入具体金额"></el-input>
          </div>
        </div>
        <div style="flex:0.8">
          <div class="Actit" @click="AddXs(4)"  style="margin-left:20px;cursor: pointer;">查询</div>
          <div class="Actit dsdsdsdaaa" @click="AddXs(10)"  style="margin-left:20px;cursor: pointer;">重置</div>
        </div>
        <div style="flex:1"></div>
      </div>
    </div>

    <div class="Tables">
      <div class="Tab_T Tab_y" style="border: 1px solid #FF4D4F;color:#FF4D4F"  
       v-if="TextMoMo('customer:contract:del')" @click="Add_Del(2)"><i class="el-icon-delete"></i>批量删除</div>
        <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
        <div class="dsds">
          <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
        </div>
        <div v-if="TextMoMo('workbench:sales:middle:add')">
          <el-table
            :data="tableData"
            class="table"
            ref="multipleTable"
            @row-click="handleRowClick" 
            header-cell-class-name="table-header"
            @selection-change="handhange">
            <el-table-column
              type="selection"
              width="55"
              align="center"></el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              width="55"
              align="center"></el-table-column>
            <el-table-column align="center" prop="customer.username" label="姓名"></el-table-column>
            <el-table-column align="center" prop="customer.mobile" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="goods_name" label="购买项目">
              <template #default="scope" >
                <div v-for="item in scope.row.goods" :key="item">{{item}}</div>
              </template>    
            </el-table-column>
            <el-table-column align="center" prop="pay_price" label="支付金额"></el-table-column>
            <el-table-column align="center" prop="unpaid_money" label="剩余金额"></el-table-column>
            <el-table-column align="center" prop="staff.realname" label="员工"></el-table-column>
            <el-table-column align="center" prop="confirm_status_text" label="重要提醒"></el-table-column>
            <el-table-column label="操作" align="center" width="300">
              <template #default="scope" >
                  <div class="el_butto" >
                    <el-button type="text" @click="Handlbchak(3, scope.row)" v-if="TextMoMo('customer:contract:read')">查看</el-button>
                    <el-button type="text" v-if="!TextMoMo('customer:contract:read')">查看</el-button>
                  </div>
                  <div class="el_butto" >
                    <el-button type="text" @click="Handlbchak(1, scope.row)" v-if="TextMoMo('customer:contract:edit')">编辑</el-button>
                  </div>
                  <div class="el_butto" >
                    <el-button
                    type="text" 
                    @click="Handlbchak(2, scope.row)"
                    >维护</el-button>
                  </div>
                <div class="el_butto rere" >
                    <el-button
                    type="text" v-if="TextMoMo('customer:contract:del')"
                    class="red"
                    @click="HanDel(scope.$index, scope.row)"
                    >删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @current-change="handleCurrentChange1"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
            </el-pagination>
          </div>
        </div>
        <div v-if="type_Self == 'common'">

          <div class="block">
            <el-pagination
              background
              @current-change="handleCurrentChange1"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
            </el-pagination>
          </div>
        </div>
    </div>

    <!--添加线索来源-->
    <el-dialog title="添加线索来源" v-model="dialogVisible3" width="36%">
      <div class="Tabbl">
        <el-table
        :data="Categ"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>

      <div class="Y_ids" @click="Add_leibie"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN()">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--添加线索来源-->

    <!--全部线索类型管理-->
    <el-dialog title="全部线索类型管理" v-model="dialogVisible2" width="35.3%"> 
      <div class="Tabbl">
        <el-table
        :data="Categ2"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input type="number" :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete2(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>

      <div class="Y_ids" @click="Add_leibie2"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN2()">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--全部线索类型管理-->



    <!-- 导入文件 -->
    <el-dialog title="导入文件" v-model="editVisible_WJD" width="31%" class="">
        <el-form ref="form" label-width="120px">
          <div class="DDGGD">
          <el-form-item label="选择导入文件：" style="margin-bottom:5px">
            <div class="el-form-item__content">
              <el-upload
                  :action="upload"
                  :headers="heads"
                  :multiple="false"
                  :on-success="UP_Success4">
                  <el-button size="small" type="primary">选择文件</el-button>
                  <div class="el-upload__tip">一次只能上传一个文件，且不超过10M</div>
              </el-upload>

          </div>
          </el-form-item>
          <el-form-item label="可见员工：">
            <div class="Rtty Riiiu">
                <el-input v-model="Flie_name" placeholder="类别"></el-input>
            </div>
          </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:28px">
            <el-button @click="editVisible_WJD = false" style="margin-right:15px">取 消</el-button>
            <el-button type="primary" @click="File_Sure2()">确 认</el-button>
          </span>
        </template>
      

    </el-dialog>
    <!-- 导入文件 -->

    <!-- 创建线索 -->
    <div ref="rightPanel" v-if="show1" class="rightPanel-container show">
      <div class="rightPanel-background"/>
      <div style="height:1100px;width: 900px;position: fixed;top: 0;right: 0;z-index:999;" >
        <el-scrollbar style="height:100%">
          <div class="rightPanel" style="width:860px;max-width: 860px;height: 832px;padding-bottom: 120px;">
            <div class="Rig_1" v-if="chakan111 == 1">创建订单<i class="el-icon-close" @click="show1 = false"  style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div class="Rig_1" v-if="chakan111 == 0">编辑订单<i class="el-icon-close" @click="show1 = false"  style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div style="margin:0 0 18px 24px " v-if="chakan111 == 0">
              <el-menu
                :default-active="acndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect">
                <el-menu-item index="1">销售线索</el-menu-item>
                <el-menu-item index="2">客户管理</el-menu-item>
                <el-menu-item index="3">订单管理</el-menu-item>
                <el-menu-item index="4">合同管理</el-menu-item>
                <el-menu-item index="5">跟进记录</el-menu-item>
              </el-menu> 
            </div>
            <div class="Rig_A" v-if="info_Show == 0"> 
              <div style="overflow:hidden">
                  <div style="width:50%;float:left">
                      <div class="Rig_D">
                          <div class="Rig_Ddd" style="margin-bottom:8px"> 
                            <span style="color:#FF4D4F">* </span>姓名：</div>
                          <div class="Rtty">
                          <el-input v-model="Valuea_Xiansuo.username" placeholder="" disabled class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div style="width:50%;float:left">
                      <div class="Rig_D">
                          <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 联系方式：</div>
                          <div class="Rtty">
                          <el-input v-model="Valuea_Xiansuo.mobile" placeholder="" disabled class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
              </div>
              <div style="overflow:hidden">
                  <div style="width:50%;float:left">
                      <div class="Rig_D">
                          <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 需求来源：</div>
                          <el-select v-model="Valuea_Xiansuo.source_id" placeholder="" disabled>
                            <el-option v-for="item in leads_source" :key="item.value" :label="item.content" :value="item.value"></el-option>
                          </el-select>
                      </div>
                  </div>
                  <div style="width:50%;float:left">
                      <div class="Rig_D ">
                          <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 需求价值：</div>
                          <el-select v-model="Valuea_Xiansuo.worth_id" placeholder="" disabled>
                              <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
                          </el-select>
                      </div>
                  </div>
              </div>
              <div class="Rig_D Tigtgg" style="width:95%">
                <div class="Rig_Ddd" style="margin-bottom:9px">需求项目：</div>
                <div class="Rtty" style="clear:both">
                  <el-select v-model="valueA_3" multiple disabled >
                      <el-option v-for="item in leads_demand" :key="item.value" :label="item.content" :value="item.value"></el-option>
                  </el-select>
                </div>
              </div> 
              <div class="Rig_D" style="width:98%">
                  <div class="Rig_Ddd" style="margin-bottom:9px">描述（选填）：</div>
                  <div class="Rtty">
                      <el-input type="textarea" v-model="Valuea_Xiansuo.description" disabled style="width: 97.5%;"></el-input>           
                  </div>
              </div>
              <div>
                <div class="Rig_Ddd" style="margin-bottom:9px">图片线索（选填）</div>
                <div class="Rtty" style="clear:both">
                  <img class="Rtt_im22g" v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item"   />
                </div>
              </div>
              <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:30px" @click="show1 = false">取消</div>
            </div>
            <div class="Rig_A" v-if="info_Show == 1">
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                  <span style="color:#FF4D4F">* </span>姓名：</div>
                                   <div class="Rtty"><el-input style="" v-model="Valuea_Kehu.username" disabled placeholder="" ></el-input>  </div>
                                  
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px;width: 138px;"><span style="color:#FF4D4F">* </span>性别：</div>
                                <el-select v-model="Valuea_Kehu.sex_text" placeholder="" disabled @change="changeRTY">
                                    <el-option v-for="item in SEX" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 联系方式：</div>
                                <div class="Rtty">
                                <el-input v-model="Valuea_Kehu.mobile" placeholder="" disabled class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>

                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px">微信号（选填）：</div>
                                <div class="Rtty">
                                <el-input v-model="Valuea_Kehu.weixin" placeholder="" disabled class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px;width: 138px;">工作单位（选填）：</div>
                                <div class="Rtty">
                                <el-input v-model="Valuea_Kehu.company" placeholder="" disabled class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin:25px 0 20px 0;font-weight: bold;font-size:14px">来源信息</div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 客户来源：</div>
                                <el-select v-model="Valuea_Kehu.source_id" placeholder="" disabled>
                                  <el-option v-for="item in leads_source" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                        
                        <div style="width:50%;float:left">
                            <div class="Rig_D ">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 客户价值：</div>
                                <el-select v-model="Valuea_Kehu.worth_id" placeholder="" disabled>
                                    <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 办理时间（选填）：</div>
                                <el-date-picker v-model="Valuea_Kehu.expect_time" type="month" placeholder="" disabled ></el-date-picker>
                            </div>
                        </div>
                        
                        <div style="width:50%;float:left">
                            <div class="Rig_D ">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 服务对象：</div>
                                <el-select v-model="Valuea_Kehu.service_id" placeholder="" disabled>
                                    <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>

                    <div class="Rig_D Tigtgg" style="width:95%">
                      <div class="Rig_Ddd" style="margin-bottom:9px">客户标签：</div>
                      <div class="Rtty" style="clear:both">
                        <el-select v-model="valueA_sele1" multiple placeholder="" disabled @change="changeRTY" >
                            <el-option v-for="item in leads_demand" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>  
                      </div>
                    </div> 
                    <div class="Rig_D Tigtgg" style="width:95%" >
                      <div class="Rig_Ddd" style="margin-bottom:9px">需求项目：</div>
                      <div class="Rtty" style="clear:both">
                        <el-select v-model="valueA_sele2" multiple placeholder="" disabled @change="changeRTY" >
                            <el-option v-for="item in leads_demand" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>
                      </div>
                    </div> 

                    <div class="Rig_D" style="width:98%">
                        <div class="Rig_Ddd" style="margin-bottom:9px">描述：</div>
                        <div class="Rtty">
                            <el-input type="textarea" v-model="Valuea_Kehu.description" disabled style="width:97.5%"></el-input>           
                        </div>
                    </div>
                    <div>
                      <div class="Rig_Ddd" style="margin-bottom:9px">图片线索</div>
                      <div class="Rtty" style="clear:both">
                        <img class="Rtt_im22g" v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item"   />
                      </div>
                    </div>
                    <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:30px" @click="show1 = false">取消</div>
            </div>
            <div class="Rig_A" v-if="info_Show == 2">
                    <div style="margin:8px 0 15px 0;font-size: 15px;color: #333333;font-weight: bold;">买家信息</div>
                    <div style="overflow:hidden">
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia">
                                <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                  <span style="color:#FF4D4F">* </span>买家姓名（匹配确认）：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Mai1"  placeholder="请输入姓名" class="El_ii"></el-input>
                                </div>
                                
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia">
                                <div class="Rig_Ddd" style="margin-bottom:8px;width:240px"><span style="color:#FF4D4F">* </span> 买家联系方式（匹配确认）：</div>
                                <div class="Rtty ">
                                <el-input v-model="valueA_Mai2" placeholder="请输入联系方式" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 买家来源（匹配确认）：</div>
                                <el-select v-model="valueA_Mai3" placeholder="请选择购买项目">
                                  <el-option v-for="item in leads_source" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="margin:8px 0 15px 0;font-size: 15px;color: #333333;font-weight: bold;">服务对象信息</div>
                    <div style="overflow:hidden" v-for="item in person" :key="item.mobile">
                        <div style="width:24.8%;float:left">
                            <div class="Rig_D Ruuit">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 服务对象（一）：</div>
                                <div class="Rtty ">
                                  <el-input v-model="item.username" placeholder="服务对象名称（必填）" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:24.8%;float:left">
                            <div class="Rig_D Ruuit">
                                <div class="Rig_Ddd" style="margin-bottom:11px"> &nbsp;</div>
                                <div class="Rtty ">
                                  <el-input v-model="item.mobile" placeholder="手机号（必填）" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:24.8%;float:left">
                            <div class="Rig_D Ruuit">
                                 <div class="Rig_Ddd" style="margin-bottom:11px">  &nbsp;</div>
                                <div class="Rtty ">
                                  <el-input v-model="item.company" placeholder="工作单位（全称）" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:24.8%;float:left">
                            <div class="Rig_D Ruuit">
                              <div class="Rig_Ddd" style="margin-bottom:11px">  &nbsp;</div>
                                <div class="Rtty ">
                                  <el-input v-model="item.id_card" placeholder="有效证件号" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div @click="Add_fiwu" style="margin:0px 0 20px 0;font-size: 15px;color: #1890FF;cursor: pointer;">增加服务对象</div>
                    <div style="margin:8px 0 15px 0;font-size: 15px;color: #333333;font-weight: bold;">订单信息</div>
                    <div style="overflow:hidden">
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia">
                                <div class="Rig_Ddd" style="margin-bottom:8px;width:240px"> 
                                  <span style="color:#FF4D4F">* </span>订单号（系统自动生成）：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Din1" disabled class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 购买项目：</div>
                                <el-select v-model="valueA_Din2" placeholder="请选择购买项目">
                                  <el-option v-for="item in leads_demand" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 服务商（员工）：</div>
                                <el-select v-model="valueA_Din3" placeholder="请选择销售人员是谁">
                                  <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia">
                                <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                  <span style="color:#FF4D4F">* </span>商品单价：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Din4" placeholder="请填写商品单价" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 商品数量：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Din5" placeholder="请填写商品数量" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 商品应收总金额：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Din6" placeholder="请输入订单总价" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                  <span style="color:#FF4D4F">* </span>站点：</div>
                                <el-select v-model="valueA_Din7" placeholder="请选择订单属于哪个站点">
                                  <el-option v-for="item in SiteList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>                      
                    </div>
                    <div style="margin:8px 0 15px 0;font-size: 15px;color: #333333;font-weight: bold;">付款信息</div>
                    <div style="overflow:hidden">
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 付款形式：</div>
                                <el-select v-model="valueA_Info1" placeholder="请选择付款形式">
                                  <el-option v-for="item in pay_form" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 优惠金额：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Info2" placeholder="请填写优惠金额" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 实际支付金额：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Info3" placeholder="请填写实际支付金额" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia">
                                <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                  <span style="color:#FF4D4F">* </span>剩余金额：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Info4" placeholder="请填写剩余金额" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 付款时间：</div>
                                <el-date-picker v-model="valueA_Info5" type="date" placeholder="选择月份" ></el-date-picker>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 支付方式：</div>
                               <el-select v-model="valueA_Info6" placeholder="请选择支付方式">
                                  <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="margin:8px 0 15px 0;font-size: 15px;color: #333333;font-weight: bold;">尾款信息</div>
                    <div style="overflow:hidden">
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 尾款应付时间：</div>
                                <el-date-picker v-model="valueA_Wei1" type="date" placeholder="选择尾款应付时间" ></el-date-picker>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 尾款付款时间：</div>
                                <el-date-picker v-model="valueA_Wei2" type="date" placeholder="选择尾款付款时间" ></el-date-picker>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 支付方式：</div>
                               <el-select v-model="valueA_Wei3" placeholder="请选择支付方式">
                                  <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia">
                                <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                  <span style="color:#FF4D4F">* </span>应付金额：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Wei4" placeholder="请填写剩余金额" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:33%;float:left">
                            <div class="Rig_D Ruuia3">
                                <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 实际支付金额：</div>
                                <div class="Rtty ">
                                  <el-input v-model="valueA_Wei5" placeholder="请填写实际支付金额" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin:8px 0 15px 0;font-size: 15px;color: #333333;font-weight: bold;">附件</div>
                    <div class="Rig_D" style="width:98%">
                        <div class="Rig_Ddd" style="margin-bottom:9px">订单备注（选填）：</div>
                        <div class="Rtty">
                            <el-input type="textarea" style="width:99%" v-model="valueA_Wei6" placeholder="备注信息可以写在这里，方便日后更好的查询与记忆" ></el-input>           
                        </div>
                    </div>
                    <div>
                      <div class="Rig_Ddd" style="margin-bottom:19px"> 合同/付款凭证/重要信息等上传</div>
                      <div class="Rtty" style="clear:both">
                  
                        <img class="Rtt_im22g" v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item"   />
                        <div style="margin-top:20px">
                          <el-upload
                            :action="upload"
                            :headers="heads"
                            list-type="picture-card"
                            :on-success="UP_Success"
                            :on-remove="HAndemove"
                            :on-preview="handlePictureCardPreview">
                            <i class="el-icon-plus"></i>
                            <div class="el_asff">上传图片</div>
                          </el-upload>
                        </div>
                      </div>
                    </div>
                    <div class="BCSZ" style="margin-right:24px;" @click="Btn_cj(1)" v-if="type_Self == 'self'">保存设置</div>
                    <div class="BCSZ" style="margin-right:24px;" @click="Btn_cj(2)" v-if="type_Self == 'common'">保存设置</div>
                    <div class="BCSZ" style="margin-right:24px;background:#fff;color:#595959;border: 1px solid #D9D9D9;" @click="show1 = false">取消</div>
            </div>
            <div class="Rig_A" v-if="info_Show == 3">
                  <el-scrollbar>
                    <div class="rightPanel" style="max-width:1000px;height:1042px">
                      <div class=" Riyy">
                        <div class="Ri_tit">基本信息</div>
                        <div class="Riidid">
                            <div>
                                <div class="Rig_D" style="margin-right:15px">
                                    <div class="Rig_Ddd" style="margin-bottom:4px"><span>*</span> 姓名</div>
                                    <div class="Rtty" >
                                    <el-input v-model="Customer.username" placeholder="请填写客户姓名" disabled class="El_ii" ></el-input>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="Rig_D" style="margin-right:15px">
                                    <div class="Rig_Ddd" style="margin-bottom:4px"><span>*</span> 联系方式</div>
                                    <div class="Rtty">
                                    <el-input v-model="Customer.mobile" placeholder="请填写客户手机号码" disabled class="El_ii" ></el-input>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:4px">客户昵称</div>
                                    <div class="Rtty">
                                    <el-input v-model="Customer.username" placeholder="请填写客户使用的昵称" disabled class="El_ii"></el-input>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="Ri_tit" >基本信息</div>
                        <div class="Ri_cen" style="width:98%">
                            <div>
                              <span>所有的订单</span>
                              <span>{{Tongji.total}}</span>
                            </div>
                            <div>
                              <span>未完成订单</span>
                              <span>{{Tongji.unfinish}}个</span>
                            </div>
                            <div>
                              <span>已退费的订单</span>
                              <span>{{Tongji.refund}}个</span>
                            </div>
                        </div>
                        <div style="padding-right: 30px;">
                          <el-table
                            :data="OrderList"
                            border
                            align="center"
                            style="width: 100%; margin-top: 20px">
                            <el-table-column
                              prop="createtime"
                              label="购买时间" align="center"
                              width="110">
                            </el-table-column>
                            <el-table-column 
                              prop="order_no" align="center" width="150"
                              label="订单号">
                            </el-table-column>
                            <el-table-column
                              prop="goodsone.goods_name" align="center" width="120"
                              label="购买项目">
                            </el-table-column>
                            <el-table-column
                              prop="goodsone.business_username"  width="102" align="center"
                              label="服务商">
                            </el-table-column>
                            <el-table-column
                              prop="pay_type_text" align="center" width="90"
                              label="支付方式">
                            </el-table-column>          
                            
                            <el-table-column
                              prop="pay_price" align="center" width="90"
                              label="支付金额">
                            </el-table-column>  
                            <el-table-column
                              prop="order_status_text" align="center" width="80"
                              label="订单状态">
                            </el-table-column>   
                  
                            <el-table-column label="操作" align="center"  width="70">
                              <template #default="scope">
                                <div class="mmim" @click="handleEdit(1, scope.row)">查看</div>
                              </template>
                            </el-table-column>
                          </el-table>
                          <div class="Ri_tj" style="width:100%">添加</div>
                        </div>
                        <div class="Ri_tit" style="margin-top:18px">附加资料</div>
                        <div style="padding-right: 30px;">
                          <el-table
                            :data="FileList"
                            border
                            style="width: 100%; margin-top: 20px">
                            <el-table-column
                              prop="createtime" align="center"
                              label="上传时间">
                            </el-table-column>
                            <el-table-column
                              prop="name" align="center"
                              label="文件名称">
                            </el-table-column>
                            <el-table-column
                              prop="filesize" align="center" width="100"
                              label="文件大小">
                            </el-table-column>
                            <el-table-column
                              prop="staff.realname" align="center" width="100"
                              label="上传者">
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                              <template #default="scope">
                                <div class="mui">
                                  <div class="mmim " @click="handleEdit2(1, scope.row)">查看</div>
                                  <!-- <div class="mmim " @click="handleEdit2(2,scope.row)"> 下载</div> -->
                                  <div class="mmim "> 
                                    <a style="color:#1890FF" :href="scope.row.full_filepath" :download="scope.row.name + '.' + scope.row.format">下载</a>
                                  </div>
                                  <div class="mmim " @click="handleEdit2(3, scope.row)" v-if="TextMoMo('customer:contract:file:del')">删除</div>
                                </div>
                              </template>
                            </el-table-column>
                          </el-table>
                          <div class="Ri_tj" @click="TJ2(Customer.id)" style="width:100%" v-if="TextMoMo('customer:contract:file:add')">添加</div>
                        </div>
                      </div>

                    </div>
                  </el-scrollbar>
            </div> 
            <div v-if="info_Show == 4">
                    <div class="Rig_A">
                      <div class="Rig_D Tigtgg R_texxt" style="width:100%">
                        <div class="TRRE">
                          <div style="margin:8px 0 9px 0;font-size: 14px;color: #333333;">全部进程信息</div>
                          <div style="margin:20px 24px 0 1px" v-if="Flow_list.length != 0">
                              <el-scrollbar height="100%">
                                <div id="time-line">
                                  <div class="item" v-for="item in Flow_list" :key="item.index">
                                    <div class="ytyt">
                                      <div class="item-index">{{ item.createtime }}</div>
                                      <div class="item-content">{{ item.info }}</div>
                                      <img class="Rtt_im22g" v-for="item in item.imgs_url" :key="item" :label="item" :value="item" :src="item"   />
                                    </div>
                                  </div>
                                </div>
                              </el-scrollbar>
                          </div>
                        </div>
                        <div class="Rig_D" style="position: relative;width:100%">
                            <div class="Rig_Ddd" style="margin-bottom:9px;margin-top:30px;width:100%"> <span style="color:#FF4D4F">*</span> 文字记录</div>
                            <div class="Rtty">
                                <el-input type="textarea" style="" v-model="Info_desc" 
                                placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率
① 选择常用语更新记录
② 手动输入文字信息
③ 将重要的凭证、历史沟通、其他社交媒体截图等在下方上传保存"></el-input>           
                            </div>     
                          <div class="Rtty Riuo fdgfg" style="clear:both;"> 
                            <div style="margin:4px 23px 0 0;float:right">
                                <el-checkbox v-model="valueA_input1" label="同步给客户"></el-checkbox>
                            </div>
                          </div>
                        </div>  
                        <div>
                          <div class="Rig_Ddd" style="margin:28px 0 15px 0">图片线索（选填）</div>
                          <div class="Rtty TUUu" style="clear:both"> 
                              <div style="margin-top:20px">
                                <el-upload
                                  :action="upload"
                                  :headers="heads"
                                  list-type="picture-card"
                                  :on-success="UP_Success2"
                                  :on-remove="HAndemove2"
                                  :on-preview="handlePictureCardPreview">
                                  <i class="el-icon-plus"></i>
                                  <div class="el_asff">上传图片</div>
                                </el-upload>
                              </div>
                          </div>
                        </div>

                        <div class="Rtty">
                            <div class="BCSZ" style="margin-right:24px;margin-bottom:0;" @click="Btn_dsa">保存设置</div>
                            <div class="BCSZ" @click="show1 = false" style="margin-bottom:0;background:#fff;color:#595959;border: 1px solid #D9D9D9;">取  消</div>
                        </div>
                      </div> 

                    </div>
                
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <!-- 创建线索 -->

    <!-- 创建线索 -->
    <div ref="rightPanel" v-if="show11" class="rightPanel-container show">
          <div class="rightPanel-background"/>
          <div style="height:1100px;width: 860px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="width:860px;max-width: 860px;">
                <div class="Rig_1">查看订单<i class="el-icon-close" @click="show11 = false"  style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_A">
                  <el-scrollbar>
                    <div class="rightPanel" style="max-width:1000px;height:1042px">
                      <div class=" Riyy">
                        <div class="Ri_tit">基本信息</div>
                        <div class="Riidid">
                            <div>
                                <div class="Rig_D" style="margin-right:15px">
                                    <div class="Rig_Ddd" style="margin-bottom:4px"><span>*</span> 姓名</div>
                                    <div class="Rtty" >
                                    <el-input v-model="Customer.username" placeholder="请填写客户姓名" disabled class="El_ii" style="width:100%"></el-input>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="Rig_D" style="margin-right:15px">
                                    <div class="Rig_Ddd" style="margin-bottom:4px"><span>*</span> 联系方式</div>
                                    <div class="Rtty">
                                    <el-input v-model="Customer.mobile" placeholder="请填写客户手机号码" disabled class="El_ii" style="width:100%"></el-input>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:4px">客户昵称</div>
                                    <div class="Rtty">
                                    <el-input v-model="Customer.username" placeholder="请填写客户使用的昵称" disabled class="El_ii"></el-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="Ri_tit" >基本信息</div>
                        <div class="Ri_cen" style="width:98%">
                            <div>
                              <span>所有的订单</span>
                              <span>{{Tongji.total}}</span>
                            </div>
                            <div>
                              <span>未完成订单</span>
                              <span>{{Tongji.unfinish}}个</span>
                            </div>
                            <div>
                              <span>已退费的订单</span>
                              <span>{{Tongji.refund}}个</span>
                            </div>
                        </div>
                        <div style="padding-right: 30px;">
                          <el-table
                            :data="OrderList"
                            border
                            align="center"
                            style="width: 100%; margin-top: 20px">
                            <el-table-column
                              prop="createtime"
                              label="购买时间" align="center"
                              width="110">
                            </el-table-column>
                            <el-table-column 
                              prop="order_no" align="center" width="150"
                              label="订单号">
                            </el-table-column>
                            <el-table-column
                              prop="goodsone.goods_name" align="center" width="120"
                              label="购买项目">
                            </el-table-column>
                            <el-table-column
                              prop="goodsone.business_username"  width="102" align="center"
                              label="服务商">
                            </el-table-column>
                            <el-table-column
                              prop="pay_type_text" align="center" width="90"
                              label="支付方式">
                            </el-table-column>          
                            
                            <el-table-column
                              prop="pay_price" align="center" width="90"
                              label="支付金额">
                            </el-table-column>  
                            <el-table-column
                              prop="order_status_text" align="center" width="80"
                              label="订单状态">
                            </el-table-column>   
                  
                            <el-table-column label="操作" align="center"  width="70">
                              <template #default="scope">
                                <div class="mmim" @click="handleEdit(1, scope.row)">查看</div>
                              </template>
                            </el-table-column>
                          </el-table>
                          <div class="Ri_tj" style="width:100%">添加</div>
                        </div>
                        <div class="Ri_tit" style="margin-top:18px">附加资料</div>
                        <div style="padding-right: 30px;">
                          <el-table
                            :data="FileList"
                            border
                            style="width: 100%; margin-top: 20px">
                            <el-table-column
                              prop="createtime" align="center"
                              label="上传时间">
                            </el-table-column>
                            <el-table-column
                              prop="name" align="center"
                              label="文件名称">
                            </el-table-column>
                            <el-table-column
                              prop="filesize" align="center" width="100"
                              label="文件大小">
                            </el-table-column>
                            <el-table-column
                              prop="staff.realname" align="center" width="100"
                              label="上传者">
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                              <template #default="scope">
                                <div class="mui">
                                  <div class="mmim ">查看</div>
                                  <div class="mmim "> 
                                    <a style="color:#1890FF">下载</a>
                                  </div>
                                  <div class="mmim ">删除</div>
                                </div>
                              </template>
                            </el-table-column>
                          </el-table>
                          <div class="Ri_tj" style="width:100%">添加</div>
                        </div>
                      </div>

                    </div>
                  </el-scrollbar>
                </div> 

              </div>
            </el-scrollbar>
          </div>
    </div>
    <!-- 创建线索 -->
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue"; 
export default {
  name: "basetable",
  data() {
    return {
      Customer_id:0,
      TA_name1:'',
      TA_name11:'',
      TA_name111:'',
      TA_name1_Arr:[],
      Flie_name:'',
      Flie_name:'',
      TA_name2:'订单管理',
      TA_name22:'',
      TA_name222:'',
      TA_name333:'',
      TA_name3:'',
      Show:false,
      Inpup:'',
      reverse: true,
      Flow_list:[],
      
      info_Show:0 ,
      checked_kehu:false,
      activeIndex: '4',
      acndex:'1',
      dialogImageUrl: '',
      radio:'1',

      show1:false,
      show11:false,
      editVisible_WJD:false,
      dialogVisible2: false,  
      dialogVisible3: false,  
      dialogVisible4: false,
      multipleSelection_id:null,   //勾选的信息
      multipleSelection:[],
      Ta_Shsh:0,

      tableData: [],
      total1:0,
      total2:0,
      currentPage1:0,
      currentPage2:0,

      idW:0,
      idY:0,
      idG:0,

      Categ:[],
      Categ2:[],
      input_seach:'',
      type_Self: 'self',
      dialogVisible3_e:0,
      dialogVisible2_e:0,

      Config1:[],
      Config2:[],
      Config3:[],
      Config4:[],
      Config5:[],
      Config6:[],
      leads_source:[],  //线索来源
      leads_source_W:[],  //线索来源
      leads_source_id:0,  //线索来源
      leads_source_value:0,

      leads_demand:[],  //线索需求
      leads_demand_W:[],  //线索需求
      leads_demand_id:0,  //线索需求
      leads_demand_value:0,  //线索需求

      Months1:'',
      Months2:'',
      Months3:'',
      Months4:'',

      se_id:0,
      wo_id:0,


      Case_attse:[],  //可见部门
      chakan111:0,
      Hand_id:0,
      Customer_id:0,

      SiteList:[],      //站点列表
      person:[
        {mobile:'',username:'',company:'',id_card:''}
      ],        //服务对象
      pay_form:[{id:'all',name:'全款'},{id:'part',name:'部分'}],
      radio1:'天',

      valueA_Mai1:'',
      valueA_Mai2:'',
      valueA_Mai3:'',

      valueA_Din1:'',
      valueA_Din2:'',
      valueA_Din3:'',
      valueA_Din4:'',
      valueA_Din5:'',
      valueA_Din6:'',
      valueA_Din7:'',

      valueA_Info1:'',
      valueA_Info2:'',
      valueA_Info3:'',
      valueA_Info4:'',
      valueA_Info5:'',
      valueA_Info6:'',

      valueA_Wei1:'',
      valueA_Wei2:'',
      valueA_Wei3:'',
      valueA_Wei4:'',
      valueA_Wei5:'',
      valueA_Wei6:'',

      Valuea_Kehu:{},
      Valuea_Xiansuo:{},
      valueA_3:'',
      valueA_sele1:'',
      valueA_sele2:'',

      Row_Eurl:[],          //图片
      Row_Eurl2:[],          //图片
      Info_desc:'',
      Claim:{},

      File:'',
      File_Yid:[],
      File_Bid:[],
      fileList:[],

      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      upload2:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},

      MMD:[{id:'self',name:'我的订单'},{id:'common',name:'全部订单'}],
      MMD_detail:{},
      editVisible:false,
      Customer_worth:[],
      Customer_Self:'',
      StaFFList:[],    ///////////// 全部员工列表
      DementList:[],   ///////////// 全部部门列表

      fForm:{},
      ConfigList:{},
      Fwus_list:[],
      BusinessList:[],
      OPzt:[{name:'已支付',id:20},{name:'未支付',id:10}],
      OPzt2:[{name:'未接单',id:10},{name:'已接单',id:20}],
      OPzt3:[{name:'退款中',id:20},{name:'未退款',id:10},{name:'已退款',id:10}],
      OPzt4:[{name:'全部',id:'all'},{name:'部分',id:'part'}],

      Row :[],
      Tongji:[],
      OrderList:[],
      FileList:[],
      Customer:[]
    };
  },
  mounted() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    axios.get('/admin/ajax/staffList',{   // 全部员工列表
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
              that.StaFFList = res.data.data
          }
    })  
    axios({ method: 'post', url: '/admin/ajax/goodsList', 
        }).then(function (res) {
          if(res.data.code == 0) {
          } else {
            that.leads_source = res.data.data
            that.leads_source_W = res.data.data.slice()   //复制一个数组
            that.leads_source_value = 0
          }
    })
    axios.get('/admin/company/department/index',{   // 全部部门列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DementList = res.data.data.rows
        }
    })
    axios.get('/admin/ajax/allBusiness',{   // 服务商列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.Fwus_list = res.data.data
          console.log(that.Fwus_list)
        }
    })
    axios.get('/admin/ajax/businessList',{   // 服务商
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.BusinessList = res.data.data
        }
    })
    axios.get('/admin/customer/contract/statisticsColumns',{
      }).then(function (res) {
        if (res.data.code == 401) {
            // that.$router.push('/Login');
        } else{
          that.leads_demand = res.data.data.order_sale_goods
          console.log(that.leads_demand)
          that.leads_demand_W = res.data.data.order_sale_goods.slice()
          that.leads_demand_value = 0
      }
    })  
    that.AAAAA()
    axios.get('/admin/customer/customer/statisticsColumns',{
      }).then(function (res) {
        if (res.data.code == 401) {
            // that.$router.push('/Login');
        } else{
          that.Customer_worth = res.data.data.customer_worth
      }
    })  
    axios.get('/admin/customer/config/index',{   // 全部部门列表
            params:{
              type: 'order'
            }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.ConfigList = res.data.data.config
        }
    })  
    axios.get('/admin/ajax/siteList',{   // 站点列表    
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.SiteList = res.data.data
        }
    })     
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    handleRowClick(row, column, event){
          this.$refs.multipleTable.toggleRowSelection(row);
    },
    UP_Success4 (response, file, fileLis) {
      var that = this
      that.Flie_name = file.name
      that.Flie_ffff = response.data.url
    },
    TJ2(e) {
      var that = this
      this.editVisible_WJD = true;
      that.WDJ_id = e
    },
    File_Sure2(e) {
      var that = this
      axios({
          method: 'post',
          url: '/admin/customer/contract/addFile', 
          data: {
            row:{
              customer_id:that.WDJ_id,
              name:that.Flie_name,
              filepath:that.Flie_ffff,
            }

          },
        }).then(function (res) {
          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
              that.$message.success(res.data.msg);
              that.editVisible_WJD = false
                axios.get('/admin/customer/contract/read',{   // 线索详情
                      params:{
                        id: that.Hand_id
                      }
                  }).then(function (res) {
                    if (res.data.code == 0) {
                      alert('请求失败');  
                    } else { 
                        that.Row = res.data.data.row
                        that.Tongji = res.data.data.tongji
                        that.OrderList = res.data.data.orderList
                        that.FileList = res.data.data.fileList
                        that.Customer = res.data.data.customer
                    }
                })
          }
        })       
    },

    handleEdit2(e, data) {
      var that = this
      if (e == 1) {

      } if (e == 2) {
        require.ensure([], () => {
          const { export_json_to_excel } = require('../../excel/Export2Excel');
          const tHeader = ['上传时间', '文件名称', '文件大小', '上传者'];
          // 上面设置Excel的表格第一行的标题
          const filterVal = ['createtime', 'name', 'filesize', 'staff.realname'];
          // 上面的index、nickName、name是tableData里对象的属性
          const list = this.FileList;  //把data里的tableData存到list
          const data2 = this.formatJson(filterVal, list);
          export_json_to_excel(tHeader, data2, data.name);    
        })
      } if (e == 3) {
        that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
          }).then(() => {
              axios.delete('/admin/customer/contract/delFile',{ 
                  params:{
                        id: data.id
                  }
                }).then(function (res) {
                if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
                } else{ 
                    that.$message.success(res.data.msg);
                    axios.get('/admin/customer/contract/read',{   // 线索详情
                          params:{
                            id: that.Hand_id
                          }
                      }).then(function (res) {
                        if (res.data.code == 0) {
                          alert('请求失败');  
                        } else { 
                            that.Row = res.data.data.row
                            that.Tongji = res.data.data.tongji
                            that.OrderList = res.data.data.orderList
                            that.FileList = res.data.data.fileList
                            that.Customer = res.data.data.customer
                        }
                    })
                }
              })   
          })
     
      }
    },
    Add_fiwu(e) {
      var that= this
      var arr = that.person
      var att = {
          mobile:'',username:'',company:'',id_card:''
      }
      arr.push(att)
      that.person = arr
    },
    Btn_cj(e) {         // 
      var that = this
      var a1 = that.formatDate(that.valueA_Wei1)
      var a2 = that.formatDate(that.valueA_Wei2)
      console.log(that.person)
      if (that.chakan111 == 1) {
        axios({
          method: 'post',
          url: '/admin/customer/order/add', 
          data: {
            row:{
              username: that.valueA_Mai1, //客户姓名
              mobile: that.valueA_Mai2, //手机号
              source_id: that.valueA_Mai3,  //买家来源

              order_no: that.valueA_Din1, //订单号
              goods_id: that.valueA_Din2,    //购买项目
              staff_id:that.valueA_Din3,    //服务商id
              goods_price: that.valueA_Din5, //商品单价
              goods_num: that.valueA_Din4, //商品数量
              total_price: that.valueA_Din6,   //总金额
              site_id:that.valueA_Din7,       //站点

              pay_form:that.valueA_Info1,      //付款形式
              discount:that.valueA_Info2,      //优惠金额
              pay_price:that.valueA_Info3,      //实际支付金额
              unpaid_money:that.valueA_Info4,      //剩余金额
              pay_time:that.valueA_Info5,      //付款时间
              pay_type:that.valueA_Info6,      //支付方式
              

              remark: that.valueA_Wei6,   //备注
              images:that.Row_Eurl, //图片凭证

            },
            person: that.person, 
            unpaid:{
              expect_pay_time: a1,  //尾款应付时间
              pay_time: a2,        //尾款付款时间
              pay_type:that.valueA_Wei3,        //尾款支付方式
              payable_money:that.valueA_Wei4,  //尾款应付时间
              pay_money:that.valueA_Wei5,  //尾款应付时间
            },
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
            console.log('qweqwe')
          }else {
            that.$message.success(res.data.msg);
            that.show1 = false
            that.AAAAA()  
          }
        
        })
      } else if (that.chakan111 == 0) {
        axios({
          method: 'put',
          url: '/admin/customer/order/edit', 
          data: {
            row:{
              username: that.valueA_Mai1, //客户姓名
              mobile: that.valueA_Mai2, //手机号
              source_id: that.valueA_Mai3,  //买家来源

              order_no: that.valueA_Din1, //订单号
              goods_id: that.valueA_Din2,    //购买项目
              staff_id:that.valueA_Din3,    //服务商id
              goods_price: that.valueA_Din5, //商品单价
              goods_num: that.valueA_Din4, //商品数量
              total_price: that.valueA_Din6,   //总金额
              site_id:that.valueA_Din7,       //站点

              pay_form:that.valueA_Info1,      //付款形式
              discount:that.valueA_Info2,      //优惠金额
              pay_price:that.valueA_Info3,      //实际支付金额
              unpaid_money:that.valueA_Info4,      //剩余金额
              pay_time:that.valueA_Info5,      //付款时间
              pay_type:that.valueA_Info6,      //支付方式
              

              remark: that.valueA_Wei6,   //备注
              images:that.Row_Eurl, //图片凭证

            },
            person: that.person, 
            unpaid:{
              expect_pay_time: a1,  //尾款应付时间
              pay_time: a2,        //尾款付款时间
              pay_type:that.valueA_Wei3,        //尾款支付方式
              payable_money:that.valueA_Wei4,  //尾款应付时间
              pay_money:that.valueA_Wei5,  //尾款应付时间
            },
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
            console.log('qweqwe')
          }else {
            that.$message.success(res.data.msg);
            that.show1 = false
            that.AAAAA()  
          }
        
        })
      }
       
    },
    export2Excel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require('../../excel/Export2Excel');
        const tHeader = ['买家姓名', '联系方式', '订单号', '购买项目' , '支付金额', '剩余金额', '支付方式', '接单状态'];
        // 上面设置Excel的表格第一行的标题
        const filterVal = ['username', 'mobile', 'order_no', 'goods_name', 'pay_price', 'unpaid_money', 'pay_type_text', 'confirm_status_text'];
        // 上面的index、nickName、name是tableData里对象的属性
        const list = this.tableData;  //把data里的tableData存到list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '销售线索');    
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    beforeUpload(file) {                             // 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传
        //文件类型
        const isType = file.type === 'application/vnd.ms-excel'
        const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const fileType = isType || isTypeComputer
        if(!fileType) {
            this.$message.error('上传文件只能是xls/xlsx格式！')
        }

        // 文件大小限制为10M
        const fileLimit = file.size / 1024 / 1024 < 10;
        if(!fileLimit) {
            this.$message.error('上传文件大小不超过10M！');
        }
        return fileType && fileLimit
    },

    UP_Success3(e) {                //图片上传成功
      var that = this
      console.log(e)
      that.File = e.data.url
      that.aTY = 0
      console.log(that.File)
    },
    fileRemove(file, fileList) {                    // 移除选择的文件
        if(fileList.length < 1) {
            this.uploadDisabled = true //未选择文件则禁用上传按钮
        }
        console.log(file)
    },
    changeRTY(e) {
      var that = this
      console.log(e)
      console.log(that.valueA_3)
    },
    Handlbchak(e,data) {
      var that = this
      that.Hand_id = data.id
      that.Customer_id = data.customer_id
      that.chakan111 = 0
      if (e == 1) {
        that.show1 = true
        that.info_Show = 3
        that.acndex = 4
      } else if( e== 2 ){
        that.info_Show = 4
        that.acndex = 5
      }else if (e == 3) {
        that.show11 = true
      }
      axios.get('/admin/customer/contract/read',{   // 线索详情
            params:{
              id: data.id
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else { 
              that.Row = res.data.data.row
              that.Tongji = res.data.data.tongji
              that.OrderList = res.data.data.orderList
              that.FileList = res.data.data.fileList
              that.Customer = res.data.data.customer
          }
      })
      axios.get('/admin/customer/flow/read',{   // 线索 进程信息
            params:{
              customer_id:that.Customer_id,
              type: "contract", 
              source_id: data.id, 
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else { 
            
            that.Flow_list = res.data.data.flow_list

          }
      }) 
    },
    Close() {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.show1 = false;
    },

    submitUpload() {
      this.$refs.upload.submit();
    },
    Btn_dsa() {                     //  进程提交
      var that = this
      axios({
          method: 'post',
          url: '/admin/customer/flow/add', 
          data: {
            row:{
              customer_id:that.Customer_id,
              info: that.Info_desc, //进程的信息
              type: "contract", //类型:order=线索,=客户,order=订单,contract=合同
              source_id: that.Hand_id, //当前类型所在列表ID
              images: that.Row_Eurl2
            }
          },
        }).then(function (res) {
          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
              that.$message.success(res.data.msg);
              axios.get('/admin/customer/flow/read',{   // 合同 进程信息
                  params:{
                    customer_id:that.Customer_id,
                    type: "contract", 
                    source_id: that.Hand_id, 
                  }
              }).then(function (res) {
                if (res.data.code == 0) {
                  alert('请求失败');  
                } else { 
                  that.Flow_list = res.data.data.flow_list
                  that.Info_desc = ''
                }
             }) 
                }
          
        })       
    },

    AddXs(e) {                      // 
      var that = this
      that.dialogVisible3_e = e
      let y1 =''
      let y2 =''
      console.log(that.Months2)
      if(that.Months1 != '') {
         y1 = that.formatDate(that.Months1);
      }
      if(that.Months2 != '') {
         y2 = that.formatDate(that.Months2);
      }
      if (e == 1) {
        that.dialogVisible3 = true
        that.Categ = that.leads_source_W
      } else if (e == 2) {
        that.dialogVisible3 = true
        that.Categ = that.leads_demand_W
      } else if (e == 3) {
        that.dialogVisible3 = true
        that.Categ = that.leads_worth_W
      } else if (e == 4) {
        // that.dialogVisible3 = true
        axios({
          method: 'post',
          url: '/admin/customer/contract/index', 
          data: {
            page: 1,
            offset:0,
            limit:6,
            type: that.type_Self,
            filter:{

              source_id: that.se_id,                        //订单来源id
              goods_id: that.wo_id,                         //销售项目ID

              time:{
                min:y1,
                max:y2,
              },
              money:{
                min:that.Months3,
                max:that.Months4,
              },
              keyword:that.input_seach
            }
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })
      } else if (e == 5) {
        that.chakan111 = 1
        axios.get('/admin/customer/order/info',{
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else { 
            that.valueA_Din1 = res.data.data.order_no
          }
        }) 
        that.show1 = true
        that.valueA_Mai1 = ''
        that.valueA_Mai2 = ''
        that.valueA_Mai3 = ''
        that.person = [{mobile:'',username:'',company:'',id_card:''}]
        that.valueA_Din2 = ''
        that.valueA_Din3 = ''
        that.valueA_Din5 = ''
        that.valueA_Din4 = ''
        that.valueA_Din6 = ''
        that.valueA_Din7 = ''
        that.valueA_Info1 = ''
        that.valueA_Info2 = ''
        that.valueA_Info3 = ''
        that.valueA_Info4 = ''
        that.valueA_Info5 = ''
        that.valueA_Info6 = ''
        that.valueA_Wei1 = ''
        that.valueA_Wei2 = ''
        that.valueA_Wei3 = ''
        that.valueA_Wei4 = ''
        that.valueA_Wei5 = ''
        that.Row_Eurl=[]      
        that.full_avatar=[]      
      } else if (e == 6) {
      axios({
        method: 'post',
        url: '/admin/customer/contract/index', 
        data: {
          page: 1,
          offset:0,
          limit:6,
          type: that.type_Self,
          filter:{


            source_id: that.se_id,                        //订单来源id
            goods_id: that.wo_id,                         //销售项目ID
            pay_type: that.de_id,                         //支付方式ID
            time:{
              min:y1,
              max:y2,
            },
            money:{
              min:that.Months3,
              max:that.Months4,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })   
        that.show4 = true;
        that.BBBBB()
      } else if (e == 10) {
        that.Months1 = ''
        that.Months2 = ''
        that.Months3 = ''
        that.Months4 = ''
      }
    },
    NNNNN(e){         //内容管理
      var that = this
      that.dialogVisible2 = true
      that.dialogVisible2_e = e
      
      axios.get('/admin/customer/config/statisticsColumns',{   // 设置选项 
            params:{
              type: 'order'
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
              that.Claim = res.data.data
              if (e == 1) {
                that.Categ2 = res.data.data.claim_day
                that.Config1 = res.data.data.claim_day
              } else if(e == 2) {
                that.Categ2 = res.data.data.claim_one
                that.Config2 = res.data.data.claim_one
              } else if(e == 3) {
                that.Categ2 = res.data.data.flow_min
                that.Config3 = res.data.data.flow_min
              } else if(e == 4) {
                that.Categ2 = res.data.data.export_week
                that.Config4 = res.data.data.export_week
              } else if(e == 5) {
                that.Categ2 = res.data.data.export_one
                that.Config5 = res.data.data.export_one
              } else if(e == 6) {
                that.Categ2 = res.data.data.claim_num
                that.Config6 = res.data.data.claim_num
              }
          }
      })  

    },
    formatDate(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;

        return y + "-" + MM + "-" + d
    },
    Activ(e, id) {
      var that = this
       let y1 =''
       let y2 =''
       if(that.Months1 != '') {
         y1 = that.formatDate(that.Months1);
       } else if(that.Months2 != '') {
         y2 = that.formatDate(that.Months2);
       }

      if (e == 1) {
        that.leads_source_value = id
        that.se_id = id
      } else if (e == 2) {
        that.leads_demand_value = id
        that.wo_id = id
      } else if (e == 3) {
        that.de_id =id
        that.leads_worth_value = id
      }
      axios({
        method: 'post',
        url: '/admin/customer/contract/index', 
        data: {
          page: 1,
          offset:0,
          limit:6,
          type: that.type_Self,
          filter:{
            pay_form: that.wo_id,                      
            goods_id: that.se_id,                      
            time:{
              min:y1,
              max:y2,
            },
            money:{
              min:that.Months3,
              max:that.Months4,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })
    },
    Add_leibie() {            //创建导航类别
      var that = this
      var ATT = that.Categ
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.Categ = ATT
    },  
    Add_leibie2() {            //创建导航类别    --------------全部线索管理
      var that = this
      var ATT = that.Categ2
      var srt = {
        content: "10",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.Categ2 = ATT
    }, 
    NabCH_BTN() {             // 导航确认
        var that = this 
        var ATT = that.Categ
        if (that.dialogVisible3_e == 1) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "order_sale_goods", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible3 = false
                that.Columns()
              }
          })  
        } else if(that.dialogVisible3_e == 2) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "order_sale_goods", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible3 = false
                that.Columns()
              }
          })            

        }
     
    },
    NabCH_BTN2() {             // 导航确认         --------------全部线索管理
        var that = this 
        var ATT = that.Categ2
        if (that.dialogVisible2_e == 1) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "leads_claim_day", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 2) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "leads_claim_one", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 3) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "leads_flow_min", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 4) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "leads_export_week", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 5) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "leads_export_one", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        } else if (that.dialogVisible2_e == 6) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "leads_claim_num", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible2 = false
                that.BBBBB()
              }
          })  
        }
     
    },
    haeDete(index, row) {     // 删除导航类别
      var that = this
      console.log(row)
      var ATT = that.Categ
      if (row.type == 'default') {

      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.Categ = ATT
      }
    },
    haeDete2(index, row) {     // 删除导航类别   --------------全部线索管理
      var that = this
      console.log(row)
      var ATT = that.Categ2
      if (row.type == 'default') {

      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.Categ2 = ATT
      }
    },
    Chang_ses(e) {           // 切换我的线索
      var that = this
      that.type_Self = e
       let y1 =''
       let y2 =''
       if(that.Months1 != '') {
         y1 = that.formatDate(that.Months1);
       } 
       if(that.Months2 != '') {
         y2 = that.formatDate(that.Months2);
       }
      that.multipleSelection_id = null
      that.multipleSelection = []
      console.log(e)      
      axios({
        method: 'post',
        url: '/admin/customer/contract/index', 
        data: {
          page: 1,
          offset:0,
          limit:6,
          type: that.type_Self,
          filter:{


            source_id: that.se_id,                        //订单来源id
            goods_id: that.wo_id,                         //销售项目ID

            time:{
              min:y1,
              max:y2,
            },
            money:{
              min:that.Months3,
              max:that.Months4,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })      
    },
    inpu_sech() {             // 搜索
      var that = this
       let y1 =''
       let y2 =''
       if(that.Months1 != '') {
         y1 = that.formatDate(that.Months1);
       } 
       if(that.Months2 != '') {
         y2 = that.formatDate(that.Months2);
       }
      axios({
        method: 'post',
        url: '/admin/customer/contract/index', 
        data: {
          page: 1,
          offset:0,
          limit:6,
          type: that.type_Self,
          filter:{


            source_id: that.se_id,                        //订单来源id
            goods_id: that.wo_id,                         //销售项目ID

            time:{
              min:y1,
              max:y2,
            },
            money:{
              min:that.Months3,
              max:that.Months4,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })   
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
    },
    handleSelect_top (e) {
      if (e == 1) {
        this.$router.push('/Workbench/Manage');
      }else if (e == 2) {
        this.$router.push('/Workbench/Manage_2');
      } else if (e == 3) {
        this.$router.push('/Workbench/Manage_3');
      } else if (e == 4) {
        this.$router.push('/Workbench/Manage_4');
      }else if (e == 5) {
        // this.$router.push('/Contract');
      }
    },
    handleSelect(key, keyPath) {
      var that = this
      console.log(key);
      console.log(keyPath);
      if (key == 1) {
        if (that.Customer_id == 0) {

        } else {
          this.info_Show = 0;
          axios.get('/admin/customer/leads/read',{
                params:{
                  id: that.Customer_id
                }
            }).then(function (res) {
              if (res.data.code == 0) {
                // alert('请求失败');  
              } else { 
                
                that.Valuea_Xiansuo = res.data.data.row

                var arr = parseInt(res.data.data.row.demand_obj)
                var acc = []
                acc.push(arr)
                that.valueA_3 = acc
                that.full_avatar = res.data.data.row.imgs_url
                that.Row_Eurl = res.data.data.row.images
              }
          })
        }
        
      } else if(key == 2){
        if (that.Customer_id == 0) {

        } else {
          this.info_Show = 1
            axios.get('/admin/customer/customer/read',{  
                  params:{
                    id: that.Customer_id
                  }
              }).then(function (res) {
                if (res.data.code == 0) {
                  alert('请求失败');  
                } else {
                  that.Valuea_Kehu = res.data.data.row
                  console.log(that.Valuea_Kehu)
                  var acc = []
                  var add = []

                  var arr1 = res.data.data.row.tag_obj
                  var arr2 = res.data.data.row.demand_obj
                  for (let i = 0; i < arr1.length; i++) {
                    acc[i]  =  parseInt(arr1[i]);
                  }
                  console.log(acc)
                  for (let i = 0; i < arr2.length; i++) {
                    add[i]  =  parseInt(arr2[i]);
                  }
                  that.valueA_sele1 = acc
                  that.valueA_sele2 = acc
                  that.full_avatar = res.data.data.row.imgs_url
                  that.Row_Eurl = res.data.data.row.images
                }
            }) 
        }
        
      } else if(key == 3){
        this.info_Show = 2
      } else if(key == 4){  
        this.info_Show = 3
      } else if(key == 5){  
        this.info_Show = 4
      } 
    },
    changeSwitch(data,b,index) {      // 是否启用
      var that = this
    },
    Columns() {      //线索请求---------------------------------------------------------------------------------
      var that = this
        axios.get('/admin/customer/contract/statisticsColumns',{ 
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.leads_source = res.data.data.order_sale_goods
                that.leads_demand = res.data.data.order_sale_goods
            }
        })      
    },
    UP_Success(e) {  //图片上传成功
      var that = this
      console.log(e)
      that.Row_Eurl.push(e.data.url)
      that.aTY = 0
      console.log(that.Row_Eurl)
    },
    UP_Success2(e) {  //图片上传成功
      var that = this
      console.log(e)
      that.Row_Eurl2.push(e.data.url)
      that.aTY = 0
      console.log(that.Row_Eurl2)
    },
    HAndemove(file, fileList) {
      var that = this
      that.Row_Eurl = []
        console.log(fileList)
        for (let i = 0; i < fileList.length; i++) {
          const element = fileList[i];
          that.Row_Eurl.push(element.response.data.url)
         
        }
        console.log(that.Row_Eurl)
    },
    HAndemove2(file, fileList) {
      var that = this
      that.Row_Eurl2 = []
        console.log(fileList)
        for (let i = 0; i < fileList.length; i++) {
          const element = fileList[i];
          that.Row_Eurl2.push(element.response.data.url)
         
        }
        console.log(that.Row_Eurl)
    },

    handhange(e) {          // 勾选
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
          acc.push(element)
        }
              that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    HanDel(e, data) {       //单独删除
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
      }).then(() => {
            axios.delete('/admin/customer/contract/delFile',{ 
              params:{
                    ids: arr
              }
            }).then(function (res) {
            if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                that.AAAAA()
            }
          })            
      })     
    },
    Add_Del(e) {             //批量操作
        var that = this
        var Att = that.multipleSelection
        if(e == 2) {           
          if (that.multipleSelection_id == null) {      //批量删除
            that.$message.warning('请勾选信息');
          } else {
            that.$confirm("确定要删除吗？", "提示", {
              type: "primary"
          }).then(() => {
            axios({
              method: 'delete',
              url: '/admin/customer/contract/delFile',
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                  that.$message.success(res.data.msg);
                  that.AAAAA()
                }
            }) 
          })
        }
        }
    },


    Handlbc_TH(e, data) {            //点击维护
      var that = this 
      that.show1 = true
    },

    Handmuiksd(e) {
      var that = this
      var att = []
      console.log(e) 
      console.log(that.Case_attse)
      for (let i = 0; i < that.Case_attse.length; i++) {
        const element = that.Case_attse[i];
        att.push(element[1])
      }
      that.valueA_5 = att
      console.log(att)
    },
    Handmuiksd2(e) {
      var that = this
      var att = []
      console.log(e) 
      console.log(that.File_Bid)
      for (let i = 0; i < that.File_Bid.length; i++) {
        const element = that.File_Bid[i];
        att.push(element[1])
      }
      that.valueA_5 = att
      console.log(att)
    },
    AAAAA() {                                   ////////////////111111111111111111111111111111111111111111
      var that = this
        axios({
          method: 'post',
          url: '/admin/customer/contract/index', 
          data: {
            page: 1,
            offset:0,
            limit:6,
            type: that.type_Self,
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })  
    },
    BBBBB() {
      var that = this
        axios.get('/admin/customer/config/statisticsColumns',{   // 设置选项 
              params:{
                type: 'order'
              }
          }).then(function (res) {
            if (res.data.code == 0) {
              alert('请求失败');  
            } else{ 
                  that.Config1 = res.data.data.claim_day
                  that.Config2 = res.data.data.claim_one
                  that.Config3 = res.data.data.flow_min
                  that.Config4 = res.data.data.export_week
                  that.Config5 = res.data.data.export_one
                  that.Config6 = res.data.data.claim_num
                
            }
        })  
    },
    handleCurrentChange1(val) {    // 分页 ···············································
      var that = this
      console.log(val)
      that.currentPage1 = val
       let y1 =''
       let y2 =''
       if(that.Months1 != '') {
         y1 = that.formatDate(that.Months1);
       } 
       if(that.Months2 != '') {
         y2 = that.formatDate(that.Months2);
       }
      axios({
        method: 'post',
        url: '/admin/customer/contract/index', 
        data: {
          page: 1,
          offset:0,
          limit:6,
          type: that.type_Self,
          filter:{


            source_id: that.se_id,                        //订单来源id
            goods_id: that.wo_id,                         //销售项目ID
            time:{
              min:y1,
              max:y2,
            },
            money:{
              min:that.Months3,
              max:that.Months4,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })      
      axios({
        method: 'post',
        url: '/admin/customer/contract/index', 
        data: {
          page: 1,
          offset:0,
          limit:6,
          type: that.type_Self,
          filter:{


            source_id: that.se_id,                        //订单来源id
            goods_id: that.wo_id,                         //销售项目ID

            time:{
              min:y1,
              max:y2,
            },
            money:{
              min:that.Months3,
              max:that.Months4,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })

    },
    formatDate(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;

        return y + "-" + MM + "-" + d
    },
  },
};
</script>


<style scoped>  
/* .Manage_4 .TUUu .el-upload--picture-card {
  cursor:auto
} */
/* .Manage_4 .TUUu .el-upload--picture-card:hover, .el-upload:focus {
  border-color: #D9D9D9;
  color: #D9D9D9;
} */

.Manage_4 .dasrttr .el-input {
  width: 220px;
}
.Manage_4 .Ri_tit {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  margin-bottom: 20px;
}
.Manage_4 .Ri_cen { 
  width: 952px;
  height: 80px;
  background: #FAFAFA;
  border-radius: 4px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 19px 92px;
  box-sizing: border-box;
}
.Manage_4 .Ri_cen div span{
  display: block;
  font-size: 14px;
   
  font-weight: 400;
  color: #262626;
  text-align: center;
}
.Manage_4 .Ri_cen div span:last-child {
  margin-top: 5px;
}

</style>
